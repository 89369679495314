html,
body,
main.MuiContainer-root.MuiContainer-maxWidthLg {
  min-height: 100vh;
}

body {
  background-color: #ffffff;
  margin: 0;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  color: #1f1f1f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Iliad-Regular';
}

code {
  font-family: 'Courier New';
}

.french__map:active,
.french__map:hover {
  fill: #cc0000;
}
